import React, { Component } from "react";
import { Controller, Scene } from 'react-scrollmagic';
import harmonie from '../../assets/Elbphilharmonie_14132_1.jpg';

export default class TextAndImage1 extends Component {

  render(){
    return (
        <div className="text-and-image">
          <div className="fullscreen">
            <Controller>
              <Scene duration={`100%`} pin>
                <div className="centered-text text-align-left">
                  {this.props.lang === true ?
                  <p>Driven by an uncompromising commitment to excellence in craftsmanship and design, we create tailor-made interiors for luxury yachts and residences.</p>
                  :
                  <p>Mit einem kompromisslosen Anspruch an Handwerk und Design entwickeln wir maßgeschneiderte Interieurs für Luxusyachten und -residenzen.</p>
                  }
                </div>
              </Scene>
            </Controller>
          </div>

          <div className="fullscreen" id="fullscreen">
            <div className="centered-image">
              <img src={harmonie} className="centered-image" alt="harmonie"/>
            </div>
          </div>
        </div>
    );
  }
}