import React, { Component } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import intro from './assets/1_Intro.jpg';
import Header from './Components/Header';
import Landing from './Components/Landing';
import TextAndImage1 from './Components/TextAndImage1';
import TextAndImage2 from './Components/TextAndImage2';
import TextAndImageAndOverlay from './Components/TextAndImageAndOverlay';
import ImageThenText1 from './Components/ImageThenText1';
import ImageThenText2 from './Components/ImageThenText2';
import ImageThenText3 from './Components/ImageThenText3';
import Twins from './Components/Twins';
import Footer from './Components/Footer';
import { Controller, Scene } from 'react-scrollmagic';
import './styles.scss';

export default class App extends Component {

  constructor(props) {
    super(props)
    this.setLanguageDE = this.setLanguageDE.bind(this);
    this.setLanguageEN = this.setLanguageEN.bind(this);
    this.state = {
      showLoader: true,
      en: true
    }
  }

  componentDidMount() {
    disableBodyScroll(this.targetElement);
    this.siteLoader()
    this.setLanguageEN()
  }

  setLanguageEN() {
    this.setState({
      en: true
    });
    document.getElementById("german").classList.remove('active');
    document.getElementById("german-mobile").classList.remove('active');

    document.getElementById("english").classList.add('active');
    document.getElementById("english-mobile").classList.add('active');
  }

  setLanguageDE() {
    this.setState({
      en: false
    });
    document.getElementById("english").classList.remove('active');
    document.getElementById("english-mobile").classList.remove('active');

    document.getElementById("german").classList.add('active');
    document.getElementById("german-mobile").classList.add('active');
  }

  siteLoader() {
    if (this.state.showLoader === true) {
      setTimeout(() => {

          document.getElementById('introLogo').setAttribute('style', 'animation: logoAnimation 3s; animation-delay: 0s; animation-iteration-count: 1; animation-fill-mode:forwards; animation-timing-function: ease-out;');
          document.getElementById('intro').setAttribute('style', 'animation: introAnimation 1s; animation-delay: 3s; animation-iteration-count: 1; animation-fill-mode:forwards; animation-timing-function: ease-out;');

          setTimeout(() => {
          document.getElementById('header').setAttribute('style', 'animation: headerAnimation 1s; animation-iteration-count: 1; animation-fill-mode:forwards; animation-timing-function: ease-out;');
          document.getElementById('headingAnimated').setAttribute('style', 'animation: h1Animation 1s; animation-iteration-count: 1; animation-fill-mode:forwards; animation-delay: 1s; animation-timing-function: ease-out;');
          document.getElementById('intro').setAttribute('style', 'display: none;');
          setTimeout(
            function() {
              this.setState({showLoader: false})
              enableBodyScroll(this.targetElement);
            }
            .bind(this),
            2000
          )

          }, 4000)

      }, 1000)
    } else {
      enableBodyScroll(this.targetElement);
    }
  }

  render() {
    return (
      <div className="App">
        <div id="intro">
          <img id="introLogo" src={intro}></img>
        </div>
        <Header setLanguageEN={this.setLanguageEN} setLanguageDE={this.setLanguageDE} lang={this.state.en} />

        <Controller>
          <Landing />
          <Scene triggerHook={0} triggerElement="#trigger1" classToggle={['.header', 'trigger1-active']}>
            <div id="trigger1">
              <TextAndImage1 lang={this.state.en}/>
              <TextAndImage2 lang={this.state.en}/>
              <Twins lang={this.state.en}/>
            </div>
          </Scene>
          <Scene triggerHook={0} triggerElement="#trigger2" classToggle={['.header', 'trigger2-active']}>
            <div id="trigger2">
              <ImageThenText1 lang={this.state.en}/>
              <ImageThenText2 lang={this.state.en}/>
              <ImageThenText3 lang={this.state.en}/>
            </div>
          </Scene>
          <Scene triggerHook={0} triggerElement="#trigger3" classToggle={['.header', 'trigger3-active']}>
            <div id="trigger3">
              <TextAndImageAndOverlay lang={this.state.en}/>    
            </div>
          </Scene>
          <Footer lang={this.state.en} />
        </Controller>
      </div>
    );
  }
}
