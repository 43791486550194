import React, { Component } from 'react';
import landingImg from '../../assets/2_Boewer.jpg';

export default class Landing extends Component {

  render() {
    return (

      <div className="landing fullscreen" id="landing" name="landing">
        <img src={landingImg} className="landing-img .ie11up" alt="Landing"/>
        <div className="h1" id="headingAnimated">Bespoke Interiors</div>
      </div>
    );
  }
}
