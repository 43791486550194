import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import boewer from '../../assets/boewer_0102.jpg';

export default class Footer extends Component {
  render(){
    return(
      <div className="footerWrapper">
        <div className="fullscreen footer" id="contact" name="contact">
          <Controller>
            <Scene triggerElement={".footerWrapper"} triggerHook={1} duration={`5%`} classToggle={['.header', 'trigger5-active']}>
              <Tween from={{ backgroundColor: "transparent", opacity: "0.4" }} to={{ backgroundColor: '#000', opacity: "1"}}>
                <div className="overlay">
                <Controller>
                  <Scene triggerHook={100} duration={`300%`} classToggle={['.header', 'trigger6-active']}>
                    <div className="twins">
                      <div className="item-wrapper">
                        <div className="item item2">
                          <img src={boewer} className="item-image" alt="location"/>
                        </div>  
                        <div className="item item1">
                        {this.props.lang === true ?
                          <div className="wrapper">
                            <p className="first" >B&#214;WER GmbH</p>
                            <p>Brookweg 1 <br />49586 Neuenkirchen <br />Phone +49 (0)5465 92 92 0</p>
                            <p><a href="mailto:info@boewer.com" className="link">info@boewer.com</a><br />www.boewer.com</p>
                          </div>
                        :
                          <div className="wrapper">
                            <p className="first" >B&#214;WER GmbH</p>
                            <p>Brookweg 1 <br />49586 Neuenkirchen <br />Fon +49 (0)5465 92 92 0</p>
                            <p><a href="mailto:info@boewer.com" className="link">info@boewer.com</a><br />www.boewer.com</p>
                          </div>  
                        }
                        </div>            
                      </div>
                    </div>
                  </Scene>
                  </Controller>
                </div>
              </Tween>
            </Scene>
          </Controller>
        </div>
        <div className="imprint-wrapper">
          {this.props.lang === true ?
          <div id="imprint" className="imprint">
            <p>IMPRINT</p>
            <p>Management Board<br />Anja Böwer | Dipl.-Ing. Architecture<br />Stefan Böwer | Dipl.-Ing. Interior Architecture</p>
            <p>Böwer GmbH<br />Brookweg 1<br />49586 Neuenkirchen<br />Phone +49(0)5465.9292-0<br /><a href="mailto:info@boewer.com" className="link">info@boewer.com</a></p>
            <p>VAT ID No. DE 812880572<br />District Court Osnabrück HRB 20396<br />Tax No. 67.201.10598</p>
            <p>Concept, Text, Design:<br />Meiré und Meiré GmbH & Co. KG</p>
            <p>Photography:<br />Böwer<br />Nina Struve<br />Andreas Körner<br />Tillmann Franzen<br />Patrick Aventurier (Getty Images News/Getty Images)<br />Martin Barraud (Getty Images News/Getty Images)</p>
          </div>
          :
          <div id="imprint" className="imprint">
            <p>IMPPRESSUM</p>
            <p>Geschäftsführer<br />Anja Böwer | Dipl.-Ing. Architektur<br />Stefan Böwer | Dipl.-Ing. Innenarchitektur</p>
            <p>Böwer GmbH<br />Brookweg 1<br />49586 Neuenkirchen<br />Fon +49(0)5465.9292-0<br /><a href="mailto:info@boewer.com" className="link">info@boewer.com</a></p>
            <p>USt-IdNr. DE 812880572<br />Amtsgericht Osnabrück HRB 20396<br />Steuer-Nr. 67.201.10598</p>
            <p>Konzeption, Text, Gestaltung:<br />Meiré und Meiré GmbH & Co. KG</p>
            <p>Fotografie:<br />Böwer<br />Nina Struve<br />Andreas Körner<br />Tillmann Franzen<br />Patrick Aventurier (Getty Images News/Getty Images)<br />Martin Barraud (Getty Images News/Getty Images)</p>
          </div>
          } 
        </div>
      </div>
    );
  }
}