import React, { Component } from "react";
import { Controller, Scene } from 'react-scrollmagic';
import interior from '../../assets/boewer_sideboards_w_elm01.jpg';

export default class TextAndImage2 extends Component {
  render(){
    return (
      
      <div className="text-and-image">
        <div className="fullscreen">
        <Controller>
             <Scene duration={`100%`} pin>
              <div className="centered-text text-align-left">
                {this.props.lang === true ?
                <p>As a long-standing company with a history that spans more than 130 years, we combine a profound expertise with state-of-the-art manufacturing processes and innovative ideas.</p>
                :
                <p>Als Traditionsunternehmen mit einer über 130-jährigen Geschichte wissen wir um die zeitlose Schönheit exzellenter Handarbeit – und verbinden sie mit modernsten Fertigungsverfahren und innovativen Impulsen.</p> 
                }
              </div>
            </Scene>
          </Controller>
        </div>

        <div className="fullscreen">
          <div className="centered-image">
            <img src={interior} className="centered-image" alt="Interior"/>
          </div>
        </div>
      </div>

    );
  }
}