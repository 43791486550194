import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import hout from '../../assets/boewer_0341.jpg';

export default class ImageThenText1 extends Component {
  render(){
    return(
      <div className="image-then-text">
        <div className="fullscreen">
          <Controller>
            <Scene triggerHook={0} duration={`100%`} pin={true}>
              <div><img src={hout} className="full-image" alt="Wood"/></div>
            </Scene>
          </Controller>
        </div>
        <div className="fullscreen" id="craft">
          <Controller>
            <Scene triggerHook={100} duration={`100%`}>
              <Tween from={{ backgroundColor: 'transparent' }} to={{ backgroundColor: '#1E4A40' }}>
                  <div className="image-overlay">
                    {this.props.lang === true ?
                    <p className="fullscreen-text text-align-left" name="craft">B&#214;WER CRAFT represents tailored interiors of unrivalled perfection. With generations of experience, high-precision craftsmanship and a well-developed eye for detail, we fulfil every desire with utmost accuracy – and create room experiences of premium style and elegance.</p>
                    :
                    <p className="fullscreen-text text-align-left" name="craft">B&#214;WER CRAFT steht für maßgeschneiderte Interieurs von höchster Perfektion. Mit der Erfahrung aus Generationen, handwerklicher Präzision und einem ausgeprägten Blick für Details setzen wir jede Vorstellung punktgenau um – und schaffen Raumerlebnisse von ausgesuchter Stilsicherheit und Eleganz.</p>
                    }
                  </div>
              </Tween>
            </Scene>
          </Controller>
        </div>
      </div>
    );
  }
}