import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import tafels from '../../assets/boewer_news2014_0248_02.jpg';

export default class ImageThenText1 extends Component {
  render(){
    return(
      <div className="image-then-text">
        <div className="fullscreen">
          <Controller>
            <Scene triggerHook={0} duration={`100%`} pin={true}>
              <div><img src={tafels} className="full-image" alt="Table"/></div>
            </Scene>
          </Controller>
        </div>
        <div className="fullscreen" id="editions" name="editions">
          <Controller>
            <Scene triggerHook={100} duration={`50%`}>
              <Tween from={{ backgroundColor: 'transparent' }} to={{ backgroundColor: '#022A43' }}>
                  <div className="image-overlay">
                    {this.props.lang === true ?
                    <p className="fullscreen-text text-align-left">B&#214;WER EDITIONS brings together outstanding craftsmanship and the innovative spirit of our lab. Based on our own designs or in collaboration with renowned designers, we produce pieces of furniture which are both sophisticated and full of character - in batches or as individual pieces. B&#214;WER EDITIONS is an expression of our craftsmanship and technological expertise: tangible values that stand the test of time.</p>
                    :
                    <p className="fullscreen-text text-align-left">In den B&#214;WER EDITIONS verschmelzen exzellente Handwerkskunst und der Innovationsgeist unseres Labs. Nach eigenen Entwürfen oder in Kollaboration mit renommierten Designern fertigen wir ebenso raffinierte wie charaktervolle Möbelstücke – in Serie oder in Stückzahl eins. Die Böwer Editions sind Ausdruck unserer handwerklichen und technologischen Expertise. Anfassbare Werte, die bleiben.</p>
                    }
                  </div>
              </Tween>
            </Scene>
          </Controller>
        </div>
      </div>
    );
  }
}