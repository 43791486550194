import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';


export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({visible: !this.state.visible})
  }

  render() {
    const { visible } = this.state;
    const { setLanguageEN, setLanguageDE } = this.props;

    return(

        <div className="header notrigger-active" id="header">
          <div className="not-mobile">
            {this.props.lang === true ?
            <ul className="not-mobile">   
              <Link to="landing" smooth={true} duration={2000}><li className="boewer-logo"><svg viewBox="0 0 125 22" xmlns="http://www.w3.org/2000/svg">
                  <g id="Boewer_LOGO_RZ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g className="logo-color" fillRule="nonzero">
                          <rect id="Rectangle" x="27.7315789" y="0" width="15.9210526" height="1.70210526"></rect>
                          <path d="M45.6905263,12.9105263 C45.6905263,16.9863158 42.1473684,19.5221053 35.6978947,19.5221053 C29.2484211,19.5221053 25.7284211,16.9863158 25.7284211,12.9105263 C25.7284211,8.83473684 29.2715789,6.28736842 35.7094737,6.28736842 C42.1473684,6.28736842 45.7021053,8.82315789 45.7021053,12.9105263 M23.6326316,12.9105263 C23.6210526,18.0515789 28.0442105,21.2821053 35.6978947,21.2821053 C43.3515789,21.2821053 47.7863158,18.1094737 47.7863158,12.9684211 C47.7863158,7.82736842 43.3284211,4.65473684 35.6978947,4.65473684 C28.0673684,4.65473684 23.6210526,7.82736842 23.6210526,12.9684211" id="Shape"></path>
                          <path d="M14.9368421,19.2557895 L1.99157895,19.2557895 L1.99157895,13.5242105 L14.2768421,13.5242105 C17.4726316,13.5242105 19.4757895,14.4968421 19.4757895,16.4189474 C19.4757895,18.3410526 17.8431579,19.2557895 14.9368421,19.2557895 L14.9368421,19.2557895 Z M13.2810526,6.73894737 C16.6736842,6.73894737 18.2136842,7.54947368 18.2136842,9.35578947 C18.2136842,11.1621053 16.8242105,11.8452632 13.9178947,11.8452632 L1.99157895,11.8452632 L1.99157895,6.73894737 L13.2810526,6.73894737 Z M18.0631579,12.5284211 C19.4552394,11.942898 20.3466111,10.5644881 20.3094737,9.05473684 C20.3094737,6.36842105 17.9936842,4.97894737 13.5705263,4.97894737 L0,4.97894737 L0,20.9578947 L14.6936842,20.9578947 C19.1284211,20.9578947 21.5715789,19.3947368 21.5715789,16.5926316 C21.5715789,14.6242105 20.4136842,13.2347368 18.0978947,12.5515789 L18.0631579,12.5284211 Z" id="Shape"></path>
                          <polygon id="Path" points="75.5873684 5.03684211 69.9136842 18.4684211 64.2515789 5.03684211 62.1094737 5.03684211 56.4473684 18.4684211 50.7736842 5.03684211 48.6778947 5.03684211 48.6431579 5.12947368 55.5789474 20.9578947 57.5010526 20.9578947 63.1978947 7.51473684 68.86 20.9578947 70.7821053 20.9578947 77.7526316 5.10631579 77.7063158 5.03684211"></polygon>
                          <polygon id="Path" points="80.8442105 5.03684211 80.8442105 20.9578947 100.227368 20.9578947 100.227368 19.2673684 82.8357895 19.2673684 82.8357895 13.8368421 99.22 13.8368421 99.22 12.1347368 82.8357895 12.1347368 82.8357895 6.73894737 100.227368 6.73894737 100.227368 5.03684211"></polygon>
                          <path d="M106.051579,6.73894737 L117.074737,6.73894737 C120.587018,6.73894737 122.343158,7.67684211 122.343158,9.55263158 C122.343158,11.4284211 120.583158,12.3778947 117.074737,12.3778947 L106.051579,12.3778947 L106.051579,6.73894737 Z M117.954737,14.08 C122.389474,13.9178947 124.438947,12.0536842 124.438947,9.55263158 C124.438947,7.05157895 122.215789,5.03684211 117.410526,5.03684211 L104.06,5.03684211 L104.06,20.9578947 L106.051579,20.9578947 L106.051579,14.08 L115.395789,14.08 L122.262105,20.9578947 L124.682105,20.9578947 L124.682105,20.8652632 L117.954737,14.08 Z" id="Shape"></path>
                      </g>
                  </g>
              </svg></li></Link>
              <Link to="craft" smooth={true} duration={2000}><li>craft</li></Link>
              <Link to="lab" smooth={true} duration={2000}><li>lab</li></Link>
              <Link to="editions" smooth={true} duration={2000}><li>editions</li></Link>
              <Link to="company" smooth={true} duration={2000}><li>company</li></Link>
              <Link to="contact" smooth={true} duration={2000} className="last"><li>contact</li></Link>
              <li className="language-button english" id="english"><button onClick={setLanguageEN}>EN</button></li>
              <li className="language-button german" id="german"><button onClick={setLanguageDE}>DE</button></li>
            </ul>
            :
            <ul className="not-mobile">   
              <Link to="landing" smooth={true} duration={2000}><li className="boewer-logo"><svg viewBox="0 0 125 22" xmlns="http://www.w3.org/2000/svg">
                  <g id="Boewer_LOGO_RZ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g className="logo-color" fillRule="nonzero">
                          <rect id="Rectangle" x="27.7315789" y="0" width="15.9210526" height="1.70210526"></rect>
                          <path d="M45.6905263,12.9105263 C45.6905263,16.9863158 42.1473684,19.5221053 35.6978947,19.5221053 C29.2484211,19.5221053 25.7284211,16.9863158 25.7284211,12.9105263 C25.7284211,8.83473684 29.2715789,6.28736842 35.7094737,6.28736842 C42.1473684,6.28736842 45.7021053,8.82315789 45.7021053,12.9105263 M23.6326316,12.9105263 C23.6210526,18.0515789 28.0442105,21.2821053 35.6978947,21.2821053 C43.3515789,21.2821053 47.7863158,18.1094737 47.7863158,12.9684211 C47.7863158,7.82736842 43.3284211,4.65473684 35.6978947,4.65473684 C28.0673684,4.65473684 23.6210526,7.82736842 23.6210526,12.9684211" id="Shape"></path>
                          <path d="M14.9368421,19.2557895 L1.99157895,19.2557895 L1.99157895,13.5242105 L14.2768421,13.5242105 C17.4726316,13.5242105 19.4757895,14.4968421 19.4757895,16.4189474 C19.4757895,18.3410526 17.8431579,19.2557895 14.9368421,19.2557895 L14.9368421,19.2557895 Z M13.2810526,6.73894737 C16.6736842,6.73894737 18.2136842,7.54947368 18.2136842,9.35578947 C18.2136842,11.1621053 16.8242105,11.8452632 13.9178947,11.8452632 L1.99157895,11.8452632 L1.99157895,6.73894737 L13.2810526,6.73894737 Z M18.0631579,12.5284211 C19.4552394,11.942898 20.3466111,10.5644881 20.3094737,9.05473684 C20.3094737,6.36842105 17.9936842,4.97894737 13.5705263,4.97894737 L0,4.97894737 L0,20.9578947 L14.6936842,20.9578947 C19.1284211,20.9578947 21.5715789,19.3947368 21.5715789,16.5926316 C21.5715789,14.6242105 20.4136842,13.2347368 18.0978947,12.5515789 L18.0631579,12.5284211 Z" id="Shape"></path>
                          <polygon id="Path" points="75.5873684 5.03684211 69.9136842 18.4684211 64.2515789 5.03684211 62.1094737 5.03684211 56.4473684 18.4684211 50.7736842 5.03684211 48.6778947 5.03684211 48.6431579 5.12947368 55.5789474 20.9578947 57.5010526 20.9578947 63.1978947 7.51473684 68.86 20.9578947 70.7821053 20.9578947 77.7526316 5.10631579 77.7063158 5.03684211"></polygon>
                          <polygon id="Path" points="80.8442105 5.03684211 80.8442105 20.9578947 100.227368 20.9578947 100.227368 19.2673684 82.8357895 19.2673684 82.8357895 13.8368421 99.22 13.8368421 99.22 12.1347368 82.8357895 12.1347368 82.8357895 6.73894737 100.227368 6.73894737 100.227368 5.03684211"></polygon>
                          <path d="M106.051579,6.73894737 L117.074737,6.73894737 C120.587018,6.73894737 122.343158,7.67684211 122.343158,9.55263158 C122.343158,11.4284211 120.583158,12.3778947 117.074737,12.3778947 L106.051579,12.3778947 L106.051579,6.73894737 Z M117.954737,14.08 C122.389474,13.9178947 124.438947,12.0536842 124.438947,9.55263158 C124.438947,7.05157895 122.215789,5.03684211 117.410526,5.03684211 L104.06,5.03684211 L104.06,20.9578947 L106.051579,20.9578947 L106.051579,14.08 L115.395789,14.08 L122.262105,20.9578947 L124.682105,20.9578947 L124.682105,20.8652632 L117.954737,14.08 Z" id="Shape"></path>
                      </g>
                  </g>
              </svg></li></Link>
              <Link to="craft" smooth={true} duration={2000}><li>craft</li></Link>
              <Link to="lab" smooth={true} duration={2000}><li>lab</li></Link>
              <Link to="editions" smooth={true} duration={2000}><li>editions</li></Link>
              <Link to="company" smooth={true} duration={2000}><li>Unternehmen</li></Link>
              <Link to="contact" smooth={true} duration={2000} className="last"><li>Kontakt</li></Link>
              <li className="language-button english" id="english"><button onClick={setLanguageEN}>EN</button></li>
              <li className="language-button german" id="german"><button onClick={setLanguageDE}>DE</button></li>             
            </ul>
            }
          </div>
          <div className="mobile">
            <Link to="landing" smooth={true} duration={2000}><li className={`boewer-logo ${visible ? "hidden" : ""}`}>
            <svg viewBox="0 0 125 22" xmlns="http://www.w3.org/2000/svg">
                  <g id="Boewer_LOGO_RZ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g className="logo-color" fillRule="nonzero">
                          <rect id="Rectangle" x="27.7315789" y="0" width="15.9210526" height="1.70210526"></rect>
                          <path d="M45.6905263,12.9105263 C45.6905263,16.9863158 42.1473684,19.5221053 35.6978947,19.5221053 C29.2484211,19.5221053 25.7284211,16.9863158 25.7284211,12.9105263 C25.7284211,8.83473684 29.2715789,6.28736842 35.7094737,6.28736842 C42.1473684,6.28736842 45.7021053,8.82315789 45.7021053,12.9105263 M23.6326316,12.9105263 C23.6210526,18.0515789 28.0442105,21.2821053 35.6978947,21.2821053 C43.3515789,21.2821053 47.7863158,18.1094737 47.7863158,12.9684211 C47.7863158,7.82736842 43.3284211,4.65473684 35.6978947,4.65473684 C28.0673684,4.65473684 23.6210526,7.82736842 23.6210526,12.9684211" id="Shape"></path>
                          <path d="M14.9368421,19.2557895 L1.99157895,19.2557895 L1.99157895,13.5242105 L14.2768421,13.5242105 C17.4726316,13.5242105 19.4757895,14.4968421 19.4757895,16.4189474 C19.4757895,18.3410526 17.8431579,19.2557895 14.9368421,19.2557895 L14.9368421,19.2557895 Z M13.2810526,6.73894737 C16.6736842,6.73894737 18.2136842,7.54947368 18.2136842,9.35578947 C18.2136842,11.1621053 16.8242105,11.8452632 13.9178947,11.8452632 L1.99157895,11.8452632 L1.99157895,6.73894737 L13.2810526,6.73894737 Z M18.0631579,12.5284211 C19.4552394,11.942898 20.3466111,10.5644881 20.3094737,9.05473684 C20.3094737,6.36842105 17.9936842,4.97894737 13.5705263,4.97894737 L0,4.97894737 L0,20.9578947 L14.6936842,20.9578947 C19.1284211,20.9578947 21.5715789,19.3947368 21.5715789,16.5926316 C21.5715789,14.6242105 20.4136842,13.2347368 18.0978947,12.5515789 L18.0631579,12.5284211 Z" id="Shape"></path>
                          <polygon id="Path" points="75.5873684 5.03684211 69.9136842 18.4684211 64.2515789 5.03684211 62.1094737 5.03684211 56.4473684 18.4684211 50.7736842 5.03684211 48.6778947 5.03684211 48.6431579 5.12947368 55.5789474 20.9578947 57.5010526 20.9578947 63.1978947 7.51473684 68.86 20.9578947 70.7821053 20.9578947 77.7526316 5.10631579 77.7063158 5.03684211"></polygon>
                          <polygon id="Path" points="80.8442105 5.03684211 80.8442105 20.9578947 100.227368 20.9578947 100.227368 19.2673684 82.8357895 19.2673684 82.8357895 13.8368421 99.22 13.8368421 99.22 12.1347368 82.8357895 12.1347368 82.8357895 6.73894737 100.227368 6.73894737 100.227368 5.03684211"></polygon>
                          <path d="M106.051579,6.73894737 L117.074737,6.73894737 C120.587018,6.73894737 122.343158,7.67684211 122.343158,9.55263158 C122.343158,11.4284211 120.583158,12.3778947 117.074737,12.3778947 L106.051579,12.3778947 L106.051579,6.73894737 Z M117.954737,14.08 C122.389474,13.9178947 124.438947,12.0536842 124.438947,9.55263158 C124.438947,7.05157895 122.215789,5.03684211 117.410526,5.03684211 L104.06,5.03684211 L104.06,20.9578947 L106.051579,20.9578947 L106.051579,14.08 L115.395789,14.08 L122.262105,20.9578947 L124.682105,20.9578947 L124.682105,20.8652632 L117.954737,14.08 Z" id="Shape"></path>
                      </g>
                  </g>
              </svg></li></Link>
              <button onClick={this.toggleMenu} className="open-and-close">
                  <div className="icon">
                    <span id="burger">
                      <svg className={`burgerIcon ${visible ? "hidden" : ""}`} viewBox="0 0 34 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                              <g className="logo-color" id="Ipad_Header" transform="translate(-714.000000, -42.000000)" stroke="#FFFFFF" strokeWidth="1.5">
                                  <g transform="translate(28.000000, 38.000000)" id="Mobile_Header">
                                      <g>
                                          <g>
                                              <g id="Group" transform="translate(686.000000, 3.000000)">
                                                  <path d="M0.877192982,2.66666667 L32.4561404,2.66666667" id="Line-2"></path>
                                                  <path d="M0.877192982,13.3333333 L32.4561404,13.3333333" id="Line-2-Copy"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                    </span>
                    <span id="cross">
                      <svg className={`exit ${visible ? "" : "hidden"}`} viewBox="0 0 26 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                              <g id="Ipad_Header-MENU" transform="translate(-718.000000, -37.000000)" stroke="#FFFFFF" strokeWidth="1.5">
                                  <g id="Ipad_Header" transform="translate(28.000000, 25.000000)">
                                      <g id="Mobile_Header">
                                          <g>
                                              <g id="Group" transform="translate(703.000000, 25.000000) rotate(45.000000) translate(-703.000000, -25.000000) translate(686.000000, 8.000000)">
                                                  <path d="M0.877192982,16.6666667 L32.4561404,16.6666667" id="Line-2"></path>
                                                  <path d="M0.877192982,16.6666667 L32.4561404,16.6666667" id="Line-2-Copy" transform="translate(16.666667, 16.666667) rotate(90.000000) translate(-16.666667, -16.666667) "></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                    </span>
                  </div>
              </button>
            <ul className={`mobile ${visible ? "" : "closed"}`}>
              {this.state.visible && this.props.lang === true ?
                <div className="force-white">
                  <li className="language-button english" id="english-mobile"><button id="force-white" onClick={setLanguageEN}>English</button></li>
                  <li className="language-button german" id="german-mobile"><button id="force-white" onClick={setLanguageDE}>Deutsch</button></li>
                  <Link to="craft" href="#craft"><li id="force-white" onClick={this.toggleMenu}>craft</li></Link>
                  <Link to="lab" href="#lab"><li id="force-white" onClick={this.toggleMenu}>lab</li></Link>
                  <Link to="editions"><li id="force-white" onClick={this.toggleMenu}>editions</li></Link>
                  <Link to="company"><li id="force-white" onClick={this.toggleMenu}>company</li></Link>
                  <Link to="contact"><li id="force-white" onClick={this.toggleMenu}>contact</li></Link>
                  <Link to="imprint"><p className="imprint" onClick={this.toggleMenu}>Imprint</p></Link>
                </div>
                :
                <div className="force-white">
                  <li className="language-button english" id="english-mobile"><button id="force-white" onClick={setLanguageEN}>English</button></li>
                  <li className="language-button german" id="german-mobile"><button id="force-white" onClick={setLanguageDE}>Deutsch</button></li>
                  <Link to="craft" href="#craft"><li id="force-white" onClick={this.toggleMenu}>craft</li></Link>
                  <Link to="lab" href="#lab"><li id="force-white" onClick={this.toggleMenu}>lab</li></Link>
                  <Link to="editions"><li id="force-white" onClick={this.toggleMenu}>editions</li></Link>
                  <Link to="company"><li id="force-white" onClick={this.toggleMenu}>Unternehmen</li></Link>
                  <Link to="contact"><li id="force-white" onClick={this.toggleMenu}>Kontakt</li></Link>
                  <Link to="imprint"><p className="imprint" onClick={this.toggleMenu}>Imprint</p></Link>
                </div>
              }
            </ul> 
          </div>  
        </div>
    );
  }
}


