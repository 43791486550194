import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import glas from '../../assets/eyeem-100006491-137028712.jpg';

export default class ImageThenText1 extends Component {
  render(){
    return(
      <div className="image-then-text">
        <div className="fullscreen">
          <Controller>
            <Scene triggerHook={0} duration={`100%`} pin={true}>
              <div><img src={glas} className="full-image" alt="Glas"/></div>
            </Scene>
          </Controller>
        </div>
        <div className="fullscreen" id="lab" name="lab">
          <Controller>
            <Scene triggerHook={100} duration={`50%`}>
              <Tween from={{ backgroundColor: 'transparent' }} to={{ backgroundColor: '#201D40' }}>
                  <div className="image-overlay">
                    <div className="fullscreen-text text-align-left">
                      {this.props.lang === true ?
                      <div>    
                        <p>B&#214;WER LAB is our experimental think tank where we develop solutions for the most exclusive requirements. We research new materials, technologies and processes on a continuous basis at our workshops. Working with experts and our specialists, we develop original manufacturing processes for highly individual solutions, thus pushing the boundaries of what is possible time and time again – to make your vision a reality.</p>
                      </div>
                      :
                      <div>
                        <p>Antworten auf exklusivste Anforderungen entstehen im B&#214;WER LAB. In unseren Werkstätten erforschen wir kontinuierlich neue Materialien, Technologien und Prozesse. Mit unseren Spezialisten und in Kooperation mit Experten entwickeln wir originäre Fertigungsverfahren für hochindividuelle Lösungen. So verschieben wir immer wieder die Grenzen des Machbaren – um Ihre Vision zu verwirklichen.</p>
                      </div>
                      }
                    </div>
                  </div>
              </Tween>
            </Scene>
          </Controller>
        </div>
      </div>
    );
  }
}