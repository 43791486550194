import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import item1 from '../../assets/GettyImages-855083860.jpg';
import item2 from '../../assets/GettyImages-109350309.jpg';

export default class Twins extends Component {

  state = {
    open1: false,
    open2: false
  };

  onOpenModal1 = () => {
    this.setState({ open1: true });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  onOpenModal2 = () => {
    this.setState({ open2: true });
  };

  onCloseModal2 = () => {
    this.setState({ open2: false });
  };

  render() {
    const { open1, open2 } = this.state;

    return (
      <div className="fullscreen">
        <div className="twins modals">

          {this.props.lang === true ?
          <div className="item-wrapper">
            <div className="item item1">
              <img src={item1} className="item-image" alt="item 1"/>
              <div onClick={this.onOpenModal1} className="button button1"><p>more info</p></div>
              <Modal animationDuration={0} classNames={{overlay: 'overlay-modal overlay-modal1', modal: 'modal'}} open={open1} onClose={this.onCloseModal1} center>
                <div className="h1">YACHTS</div>
                <p>Elegance, dynamism, perfection: yachts are the perfect expression of exquisite taste and a cultivated way of life. Their construction poses a unique challenge: combining function and aesthetics in the smallest of spaces – while fulfilling the highest demands in comfort, exclusivity and a luxurious room experience. As an experienced planner and fitter, Böwer develops tailored interiors for luxury yachts, individually designed and implemented with perfect craftsmanship.</p>
                <p>Do you have more questions about our projects and services in yacht extension? Please <a href="mailto:info@boewer.com" className="link">contact us</a>.</p>
              </Modal>
              <div onClick={this.onOpenModal1} className="h2">Yachts</div>
            </div>
            <div className="item item2">
              <img src={item2} className="item-image" alt="item 2" />
              <div onClick={this.onOpenModal2} className="button button2"><p>more info</p></div>
              <Modal animationDuration={0} classNames={{overlay: 'overlay-modal overlay-modal2', modal: 'modal'}} open={open2} onClose={this.onCloseModal2} center>
                <div className="h1">RESIDENCES</div>
                <p>What makes a room a living space? A place where people can gather together, feel at home and be productive? It is a balance between functionality and aesthetics, between durability and grandeur, between modernity and timelessness. Based on this philosophy, Böwer designs, develops and constructs private rooms tailored to the needs of the people who live and work in them.</p>
                <p>Would you like to know more about our references? We will be happy to provide you with more detailed information <a href="mailto:info@boewer.com" className="link">on request</a>.</p>
              </Modal>
              <div onClick={this.onOpenModal2} className="h2">Residences</div>
            </div>              
          </div>
          :
          <div className="item-wrapper">
            <div className="item item1">
              <img src={item1} className="item-image" alt="item 1"/>
              <div onClick={this.onOpenModal1} className="button button1"><p>mehr info</p></div>
              <Modal animationDuration={0} classNames={{overlay: 'overlay-modal overlay-modal1', modal: 'modal'}} open={open1} onClose={this.onCloseModal1} center>
                <div className="h1">YACHTS</div>
                <p>Eleganz, Dynamik, Perfektion: Yachten sind der vollendete Ausdruck von exquisitem Geschmack und kultivierter Lebensart. Ihr Ausbau ist mit einer besonderen Herausforderung verbunden. Auf kleinstem Raum gilt es, Funktion und Ästhetik zu vereinen – und zugleich höchste Ansprüche an Komfort, Exklusivität und ein luxuriöses Raumerlebnis zu erfüllen. Als erfahrener Planer und Ausstatter entwickelt Böwer maßgeschneiderte Interieurs für Luxusyachten. Individuell konzipiert und mit größter handwerklicher Perfektion umgesetzt.</p>
                <p>Interessieren Sie sich für unsere Projekte und Services im Yachtausbau? Gerne stellen wir Ihnen <a href="mailto:info@boewer.com" className="link">auf Anfrage</a> nähere Informationen zur Verfügung.</p>
              </Modal>
              <div onClick={this.onOpenModal1} className="h2">Yachts</div>
            </div>
            <div className="item item2">
              <img src={item2} className="item-image" alt="item 2" />
              <div onClick={this.onOpenModal2} className="button button2"><p>mehr info</p></div>
              <Modal animationDuration={0} classNames={{overlay: 'overlay-modal overlay-modal2', modal: 'modal'}} open={open2} onClose={this.onCloseModal2} center>
                <div className="h1">RESIDENCES</div>
                <p>Was macht einen Raum zum Lebensraum? Zu einem Ort, an dem Menschen sich begegnen, sich entfalten, sich wohlfühlen, produktiv sein können? Es ist die Ausgewogenheit zwischen Funktionalität und Ästhetik, zwischen Belastbarkeit und repräsentativer Wirkung, zwischen Modernität und Zeitlosigkeit. Nach dieser Philosophie entwirft Böwer private Räume, die sich ganz nach den Menschen richten, die darin leben, wohnen und arbeiten.</p>
                <p>Wenn Sie mehr über unsere Referenzen erfahren möchten, <a href="mailto:info@boewer.com" className="link">schreiben Sie uns</a>. Gerne lassen wir Ihnen nähere Details zukommen.</p>
              </Modal>
              <div onClick={this.onOpenModal2} className="h2">Residences</div>
            </div>              
          </div>
          }
        </div>
      </div>
    );
  }
}