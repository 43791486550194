import React, { Component } from "react";
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import anjaundstefan from '../../assets/anja_und_stefan_LQ.jpg';

export default class TextAndImageAndOverlay extends Component {
  render(){
    return (
      <div className="text-and-image-and-overlay">
        <div className="fullscreen" id="company" name="company">
          <Controller>
            <Scene duration={`200%`} pin>
              <div className="centered-text text-align-left">
                {this.props.lang === true ?
                <div>
                  <div className="h2">company</div>
                  <p>B&#246;wer is an experienced full-service partner for exquisite interiors and room concepts.</p>
                </div>
                :
                <div>
                  <div className="h2">Unternehmen</div>
                  <p>B&#246;wer ist erfahrener Full-Service-Partner für exquisite Innenausbauten und Raumkonzepte.</p>
                </div>
                }
              </div>
            </Scene>
          </Controller>
        </div>

        <div className="fullscreen second">
          <Controller>
            <Scene duration={`100%`} classToggle={['.header', 'trigger4-active']} pin>
              <div className="centered-image">
                <img src={anjaundstefan} className="centered-image" alt="Anja und Stefan"/>
              </div>
            </Scene>
          </Controller>
        </div>
        <div className="fullscreen overlayfooter centered-text-background4">
          <Controller>
            <Scene triggerHook={100} duration={`100%`}>
            <Controller>
              <Scene triggerHook={100} duration={`30%`}>
                <Tween from={{ opacity: '0' }} to={{ opacity: '.8' }}>
                  <div className="fullscreen-text-overlay">
                  {this.props.lang === true ?
                    <div className="fullscreen-text text-align-left text-overflow">
                      <p>We develop extraordinary interiors for yachts and exclusive properties, taking a holistic approach, from the initial draft and technical construction to the operational implementation and key delivery.</p> 
                      <p>Working closely with our customers, we develop a tailored overall concept, coordinate the project across all planning phases and ensure that, at the end, everything is as it should be down to the smallest detail.</p>
                      <p>In addition to exceptional craftsmanship and technical and planning expertise, a unique aesthetic sensibility is provided by architects Anja and Stefan B&#246;wer – the owners and personal contacts for your project.</p> 
                      <p>Would you like to learn more? Please <a href="mailto:info@boewer.com" className="link">contact us</a>, we look forward to hearing from you.</p>
                    </div>
                   :
                    <div className="fullscreen-text text-align-left text-overflow">
                      <p>Für Yachten und exklusive Immobilien realisieren wir außergewöhnliche Interieurs mit ganzheitlichem Anspruch: von der ersten Skizze über die technische Konstruktion und operative Durchführung bis hin zur schlüsselfertigen Übergabe.</p> 
                      <p>In enger Abstimmung mit unseren Auftraggebern erstellen wir ein maßgeschneidertes Gesamtkonzept, koordinieren das Projekt über alle Planungsphasen hinweg und stellen sicher, dass am Ende alles stimmt – bis in die kleinsten Details.</p>
                      <p>Neben der handwerklichen, technischen und planerischen Kompetenz ist es eine besondere ästhetische Sensibilität, die Böwer auszeichnet. Dafür stehen die Architekten Anja und Stefan Böwer – als Inhaber wie auch als persönliche Ansprechpartner für Ihr Projekt.</p> 
                      <p>Möchten Sie mehr erfahren? Nehmen Sie <a href="mailto:info@boewer.com" className="link">hier Kontakt</a> auf - wir freuen uns, von Ihnen zu hören.</p>
                    </div>
                   } 
                  </div>
                </Tween>
              </Scene>
              </Controller>
            </Scene>
          </Controller>
          <Scene><div className="footerTrigger"></div></Scene>
        </div>
      </div>
    );
  }
}